.toastViewport {
  position: fixed;
  top: 0;
  z-index: 100;
  display: flex;
  max-height: 100vh;
  width: 100%;
  flex-direction: column-reverse;
  padding: 1rem;

  @media (min-width: 640px) {
    bottom: 0;
    right: 0;
    top: auto;
    flex-direction: column;
  }

  @media (min-width: 768px) {
    max-width: 420px;
  }
}

.toast {
  pointer-events: auto;
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  overflow: hidden;
  border-radius: 0.375rem;
  padding: 1rem 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

  &.default {
    border: 1px solid var(--color-border);
    background-color: var(--color-background);
    color: var(--color-foreground);
  }

  &.destructive {
    border: 1px solid var(--color-destructive-border);
    background-color: var(--color-destructive);
    color: var(--color-destructive-foreground);
  }

  /* State and swipe animations */
  &[data-swipe='cancel'] {
    transform: translateX(0);
  }

  &[data-swipe='end'] {
    transform: translateX(var(--radix-toast-swipe-end-x));
    animation: slide-out-to-right-full 0.4s forwards ease-in-out;
  }

  &[data-swipe='move'] {
    transform: translateX(var(--radix-toast-swipe-move-x));
    transition: none;
  }

  &[data-state='open'] {
    animation: slide-in-from-top-full 0.3s forwards ease-out;
    @media (min-width: 640px) {
      animation: slide-in-from-bottom-full 0.3s forwards ease-out;
    }
  }

  &[data-state='closed'] {
    animation: fade-out-80 0.3s forwards ease-in-out;
  }

  @keyframes slide-in-from-top-full {
    from {
      transform: translateY(-100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slide-in-from-bottom-full {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slide-out-to-right-full {
    from {
      transform: translateX(0);
      opacity: 1;
    }
    to {
      transform: translateX(100%);
      opacity: 0;
    }
  }

  @keyframes fade-out-80 {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}

.toastClose {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  border-radius: 0.375rem;
  padding: 0.25rem;

  .destructive & {
    color: var(--color-destructive-border);
  }
}

.toastDescription {
  font-size: 0.875rem;
  opacity: 0.9;
}
